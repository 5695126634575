import React, { Fragment } from "react";
import NavbarS2 from "../../components/NavbarS2/NavbarS2";
import About from "../../components/about/about";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/umgoldlogo.png";
import Ab from "../../images/about1.png";
import HeaderTop from "../../components/HeaderTop/HeaderTop";
import TeamSection from "../../components/TeamSection/TeamSection";
import GalleryPage from "../GalleryPage/GalleryPage";

const AboutPage = () => {
  return (
    <Fragment>
      <HeaderTop />
      <NavbarS2 hclass={"wpo-site-header wpo-header-style-4"} Logo={Logo} />
      <About hclass={"wpo-about-area section-padding"} Ab={Ab} />
      {/* <GalleryPage /> */}
      <Footer hclass={"wpo-site-footer"} />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
