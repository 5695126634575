import React from "react";
import { Link } from "react-router-dom";

import Ab2 from "../../images/about.png";
import Ab4 from "../../images/about3.png";

const aboutS2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className={"" + props.hclass}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <div className="wpo-about-img-wrap">
                <div className="wpo-about-img-l">
                  <img src={Ab2} alt="" />
                </div>
                <div className="wpo-about-img-r">
                  <img src={Ab4} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="wpo-about-text">
              <div className="wpo-about-title">
                <span className="sub">About Us</span>
                <h2>
                  Meet the Experts Driving <span>Quality</span> and Innovation
                  in Poultry 
                </h2>
              </div>
              <h5>
                UM GOLD LTD is a leading international trade consultant
                specializing in poultry, particularly chicken products and
                chicken feet. We offer comprehensive services to simplify global
                transactions and build strong partnerships.
              </h5>
              <p>
                We also excel in logistics and secure transactions, managing
                international shipping efficiently and safeguarding every deal
                with advanced security measures. With decades of expertise.
              </p>
              <div className="btns">
                <Link onClick={ClickHandler} to="/about" className="theme-btn">
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default aboutS2;
