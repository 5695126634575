import React, { Fragment } from "react";
import { connect } from "react-redux";
import { addToCart } from "../../store/actions/action";
import ShopProduct from "../../components/ShopProduct";
import api from "../../api";
import Scrollbar from "../../components/scrollbar/scrollbar";
import HeaderTop from "../../components/HeaderTop/HeaderTop";
import Logo from "../../images/umgoldlogo.png";
import NavbarS2 from "../../components/NavbarS2/NavbarS2";
import product from "../../api/product";

const ShopPage = () => {
  const productsArray = product;

  return (
    <Fragment>
      <HeaderTop />
      <NavbarS2 hclass={"wpo-site-header wpo-header-style-4"} Logo={Logo} />
      <section className="wpo-shop-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ShopProduct products={productsArray} />
            </div>
          </div>
        </div>
      </section>
      <Scrollbar />
    </Fragment>
  );
};

export default connect(null, { addToCart })(ShopPage);
