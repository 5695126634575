import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/umgoldlogo.png";
/* image */
import Img1 from "../../images/instragram/1.jpg";
import Img2 from "../../images/instragram/2.jpg";
import Img3 from "../../images/instragram/3.jpg";
import Img4 from "../../images/instragram/4.jpg";
import Img5 from "../../images/instragram/5.jpg";
import Img6 from "../../images/instragram/6.jpg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <footer className={"" + props.hclass}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link className="logo" to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <br />
                <p>
                  Bridging Global Trade with Unmatched Quality and Expertise. We
                  connect businesses worldwide with top-tier poultry products
                  and seamless logistics solutions.
                </p>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="#">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="#">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="#">
                      {" "}
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="#">
                      <i className="ti-google"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12"></div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact Us</h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>2 Taraqqiyot
                      Street, Tashkent, Uzbekistan
                    </li>
                    <li>
                      <i className="fi flaticon-telephone"></i>+ 99 8 99 360 22
                      26 <br />+ 99 8 33 593 88 88
                    </li>
                    <li>
                      <i className="fi flaticon-email"></i>umgoldltd@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget instagram">
                <div className="widget-title">
                  <h3>Our Gellery</h3>
                </div>
                <ul className="d-flex">
                  <li>
                    <img src={Img1} alt="" />
                  </li>
                  <li>
                    <img src={Img2} alt="" />
                  </li>
                  <li>
                    <img src={Img3} alt="" />
                  </li>
                  <li>
                    <img src={Img4} alt="" />
                  </li>
                  <li>
                    <img src={Img5} alt="" />
                  </li>
                  <li>
                    <img src={Img6} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <ul>
                <li>&copy; 2024 UM GOLD LTD All Rights Reserved.</li>
                <li>
                  <Link onClick={ClickHandler} to="#">
                    Terms of use |
                  </Link>{" "}
                  <Link onClick={ClickHandler} to="#">
                    Privacy Environmental Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
