import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage4 from "../HomePage4/HomePage4";
import AboutPage from "../AboutPage/AboutPage";
import TeamSinglePage from "../TeamSinglePage/TeamSinglePage";
import ShopPage from "../ShopPage/ShopPage";
import ProductSinglePage from "../ProductSinglePage/ProductSinglePage";

import ContactPage from "../ContactPage/ContactPage";
import ErrorPage from "../ErrorPage/ErrorPage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage4 />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route path="shop-page" element={<ShopPage />} />
          <Route path="shop-single/:slug" element={<ProductSinglePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="404" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
