import React from 'react'

function GalleryPage() {
  return (
    <div>
      Hello, this is Gallery of our Company
    </div>
  )
}

export default GalleryPage
