import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import NavbarS2 from "../../components/NavbarS2/NavbarS2";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { addToCart } from "../../store/actions/action";
import Product from "./product";
import api from "../../api";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/umgoldlogo.png";
import HeaderTop from "../../components/HeaderTop/HeaderTop";

const ProductSinglePage = (props) => {
  const { slug } = useParams();

  const productsArray = api();
  const Allproduct = productsArray;

  const { addToCart } = props;
  const [product, setProduct] = useState({});

  useEffect(() => {
    setProduct(
      Allproduct.filter((Allproduct) => Allproduct.slug === String(slug))
    );
  }, []);

  const item = product[0];

  return (
    <Fragment>
      <HeaderTop />
      <NavbarS2 hclass={"wpo-site-header wpo-header-style-4"} Logo={Logo} />
      <section className="wpo-shop-single-section section-padding">
        <div className="container">
          {item ? <Product item={item} addToCart={addToCart} /> : null}
        </div>
      </section>
      <Footer hclass={"wpo-site-footer"} />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.data.products,
  };
};

export default connect(mapStateToProps, { addToCart })(ProductSinglePage);
