import React from "react";
import { Link } from "react-router-dom";

import Abd1 from "../../images/about2.jpg";

const about = (props) => {

  return (
    <div className={"" + props.hclass}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-about-title">
                <span className="sub">About Us</span>
                <h2>
                  We're More Than a <span>Company</span>: Pioneering Quality and
                  Innovation
                </h2>
              </div>
              <h5>
                UM GOLD LTD is a premier international trade consultant
                specializing in poultry, machinery, legumes, and apparel. We
                offer comprehensive services to streamline global transactions
                and build strong partnerships. With decades of industry
                experience, our expert team ensures efficient logistics, secure
                transactions, and timely delivery. Dedicated to exceptional
                results, UM GOLD LTD supports your global business success
                across diverse sectors.
              </h5>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={props.Ab} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
